export const seo = {
  url: 'paperliner',
  title: {
    pl: 'Paperliner | Transport papieru naczepami typu Joloda',
    en: 'Paperliner | Paper transport with Joloda trailers',
  },
  desc: {
    pl: 'System Joloda to bezpieczeństwo, optymalizacja powierzchni i łatwy załadunek. Omida Transport Solution to innowacja w transporcie dla drukarni i producentów papieru.',
    en: 'The Joloda system means safety, space optimization and easy loading. Omida Transport Solution is an innovation in transport for printing houses and paper producers.',
  },
  keywords: ['paperliner', 'joloda', 'transport papieru'],
}

export const intro = {
  title: {
    pl: 'Paperliner',
    en: 'Paperliner',
  },
  desc: {
    pl: 'System Joloda to bezpieczeństwo, optymalizacja powierzchni i łatwy załadunek. Omida Transport Solution to innowacja w transporcie dla drukarni i producentów papieru.',
    en: 'The Joloda system means safety, space optimization and easy loading. Omida Transport Solution is an innovation in transport for printing houses and paper producers.',
  },
  button: {
    text: {
      pl: 'Czytaj więcej',
      en: 'Read more',
    },
    action: 'SCROLL',
  },
}

export const main = {
  body: [
    {
      headline: {
        pl: 'Bezpieczny Sposób Transportu Papieru',
        en: 'A Safe Way To Transport Paper',
      },

      texts: [
        {
          pl: 'Papier w rolach należy do ciężkich i zarazem bardzo delikatnych ładunków. Dlatego nasza firma, stawiając czoła wyzwaniu, przygotowała specjalne dedykowane rozwiązania dla producentów papieru mające na celu przyspieszenie procesu załadunku, rozładunku i transportu papieru.',
          en: 'Roll paper is a heavy and thick very delicate load. That is why our company, facing the challenge, prepared specially dedicated products for producers to accelerate the process of loading, unloading and transporting paper.',
          image: 'paperliner1',
        },
        {
          pl: 'Zastosowane rozwiązania w naczepach paper liner nie ograniczają się jedynie do transportu papieru w rolach, są idealnym środkiem do transportu produktów takich jak opakowania, prasa i książki, gdzie priorytetowe znaczenie ma szybkość załadunku i rozładunku, jak i pewność braku uszkodzeń oraz maksymalizacja wykorzystania przestrzeni ładunkowej.',
          en: 'The solutions used in paper liner trailers are not limited only to the transport of paper in rolls, they are an ideal means of transporting products such as packaging, press and books, where the priority is the speed of loading and unloading, as well as ensuring the absence of damage and maximizing the use of cargo space.',
          image: 'paperliner2',
        },
        {
          pl: 'Nasza firma, dzięki opiece spedytora 24h/7dni w tygodniu, 365 dni w roku, jest w stanie zapewnić profesjonalny serwis związany z dystrybucją prasy, który opatrzony jest wyjątkową terminowością.',
          en: 'Our company, thanks to the care of a forwarder 24/7, 365 days a year, is able to provide a professional service related to the distribution of the press, which is provided with exceptional punctuality.',
          image: 'paperliner3',
        },
      ],
    },
    {
      headline: {
        pl: 'Paperliner',
        en: 'Paperliner',
      },
      texts: [
        {
          pl: 'Kierując się zasadą innowacyjności w działaniu, przedstawiamy naczepy typu paper liner. Specjalistyczne szyny wzdłużne oraz system oczek umożliwiających adaptację klinów zabezpieczających pozwalają na szybki poziomy załadunek papieru w rolach oraz poziome umiejscowienie rolek w dowolnym punkcie na naczepie.',
          en: 'Following the principle of innovation in action, we present paper liner semi-trailers. Specialized longitudinal rails and a system of eyelets enabling the adaptation of securing wedges allow for quick horizontal loading of paper in rolls and horizontal location of the rolls at any point on the trailer. This innovative solution is not possible with standard trailers used by competitors. In addition, our semi-trailers are equipped with Joloda self-propelled trolleys, thanks to which the driver can load or unload goods without the need to use a trolley or a ramp, which significantly reduces the time of loading or unloading.',
          image: 'paperliner4',
        },
      ],
    },
    {
      headline: {
        pl: 'Joloda System',
        en: 'Joloda System',
      },
      texts: [
        {
          pl: 'Joloda to system do samodzielnego załadunku/rozładunku długich i ciężkich palet papieru w rolach oraz nietypowych maszyn. System składa się z czterech specjalnych szyn (prowadnic) zamontowanych w podłodze naczepy, po których poruszają się wyposażone w rolki listwy (Joloda). Za pomocą systemu Joloda kierowca unosi towar nad powierzchnię naczepy i przesuwa do przodu lub do tyłu.',
          en: 'Joloda is a system for self-loading / unloading of long and heavy paper pallets in rolls and atypical machines. The system consists of four special rails (guides) mounted in the floor of the trailer, on which the slats equipped with rollers (Joloda) move. With the help of the Joloda system, the driver lifts the goods above the surface of the trailer and moves it forwards or backwards.',
          image: 'paperliner5',
        },
      ],
    },
  ],
}

export const form = {
  id: 'formularz',
  title: {
    pl: 'Szybkie zapytanie Paperliner',
    en: 'Quick Message Paperliner',
  },
  desc: {
    pl: 'Wyślij zgłoszenie - skontaktujemy się z Tobą jak najszybciej ',
    en: 'Send a request - we will contact you ASAP',
  },
  fields: {
    email: {
      label: { pl: 'Email', en: 'Email' },
      placeholder: { pl: '', en: '' },
      type: 'text',
      isRequired: true,
    },
    name: {
      label: { pl: 'Osoba Kontaktowa', en: 'Contact person' },
      placeholder: { pl: '', en: '' },
      type: 'text',
      isRequired: true,
    },
    message: {
      label: { pl: 'Wiadomość', en: 'Message' },
      placeholder: { pl: '', en: '' },
      type: 'message',
      isRequired: true,
    },
  },
  agreements: [
    {
      pl: 'Wyrażam zgodę na otrzymywanie drogą elektroniczną informacji handlowych w rozumieniu ustawy z dnia 18 lipca 2002 r. o świadczeniu usług drogą elektroniczną o treści pochodzących od Omida VLS Sp. z o.o.',
      en: 'I agree to receive by e-mail commercial information coming from Omida VLS Sp. z o.o. within the meaning of the Act of 18 July 2002 on electronic services',
    },
    {
      pl: 'Wyrażam zgodę na wykorzystywanie przez Omida VLS Sp. z o.o. telekomunikacyjnych urządzeń końcowych i automatycznych systemów wywołujących celem przedstawienia informacji handlowych w rozumieniu przepisów ustawy z dnia 16 lipca 2014 r. - Prawo telekomunikacyjne',
      en: 'I hereby consent to the use by Omida VLS Sp. z o.o. of telecommunications terminal equipment and automated calling systems to present commercial information within the meaning of the Act of 16 July 2014. -Telecommunications Law',
    },
  ],
  administrating: {
    pl: 'Administratorem Państwa danych osobowych będzie Omida VLS spółka z ograniczoną odpowiedzialnością (dalej: Omida). Można się z nami skontaktować w następujący sposób: listownie na adres: Aleja Grunwaldzka 472C, 80-309 Gdańsk, przez e-mail:',
    en: 'The administrator of your personal data will be Omida VLS spółka z ograniczoną odpowiedzialnością (hereinafter: Omida). You can contact us as follows: by letter to: Aleja Grunwaldzka 472C, 80-309 Gdańsk, by e-mail:',
  },
  administratingSecond: {
    pl: ' lub telefonicznie: +48 58 741 88 14. Dane osobowe będziemy przetwarzać w celu przygotowania ofert dotyczących produktów i usług Omida. Szczegółowe informacje na temat przetwarzania danych osobowych znajdziesz',
    en: 'or by phone: +48 58 741 88 14. Personal data will be processed in order to prepare offers on Omida products and services. Details about the processing of your personal data are available',
  },
  administratingLinkText: {
    pl: 'tutaj',
    en: 'here',
  },
  dictonary: {
    success: {
      title: { pl: 'Dziękujemy!', en: 'Thank you!' },
      text: {
        pl: 'Twoja wiadomość została wysłana. Odpowiemy najszybciej jak to możliwe. Przesłaliśmy również potwierdzenie wiadomości na podany adres',
        en: 'Your message has been sent. We will reply as soon as possible. We also sent you the confirmation to provided email address',
      },
    },
    failure: {
      title: { pl: 'Niepowodzenie!', en: 'Failure!' },
      text: {
        pl: 'Wygląda na to, że Twoja wiadomość nie została wysłana. Skontaktuj się z nami drogą mailową lub telefonicznie',
        en: 'It looks like your message has not been sent. Contact us by e-mail or by phone',
      },
    },
    send: {
      pl: 'Wyślij',
      en: 'Send',
    },
    fill: {
      pl: 'Wypełnij formularz',
      en: 'Fill up the form',
    },
    fillagreements: {
      pl: 'Zaznacz zgody',
      en: 'Agree to the terms',
    },
  },
}

export const buttonBack = {
  text: {
    pl: 'Zapytaj o ofertę',
    en: 'Ask for an offer',
  },
  link: '/paperliner/#formularz',
}
